<template>
	<div>
		<b-container class="text-left">
			<router-link
				to="/jobs"
				class="nav-link"
			>
				<h5>
					<font-awesome-icon :icon="['fas', 'arrow-circle-left']" />  Go back
				</h5>
			</router-link>
		</b-container>
		<hr>
		<h1>Job information</h1>
		<div class="content-start">
			<div class="row">
				<div class="col-md-1 mb-1" />
				<div class="col-md-3 mb-3">
					<h5 class="mb-3">
						General:
					</h5>
					<p><b>Id:</b> {{ job.id }}</p>
					<p><b>Created at:</b> {{ job.createdAt }}</p>
					<p><b>Last modified at:</b> {{ job.updatedAt }}</p>
				</div>
				<div class="col-md-3 mb-3">
					<h5 class="mb-3">
						Job Settings:
					</h5>
					<p><b>Region:</b> {{ job.region }}</p>
					<p><b>Profile:</b> {{ job.profile }}</p>
				</div>
				<div class="col-md-3 mb-3">
					<h5 class="mb-3">
						Results:
					</h5>
					<span v-if="job.state=='finished'">
						<b-button
							class="mb-2 btn-success"
							variant="primary"
							size="sm"
							@click="downloadResults"
						>Download results</b-button>
					</span>
					<p><b>State:</b> {{ job.state }}</p>
					<p v-if="job.message">
						<b>Message:</b> {{ job.message }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Job',
	data () {
		return {
			job: {},
		}
	},

	created() {
		let id = this.$route.params.jobid
		return this.$api.request(`/private/jobs/${id}`)
			.then(response=>{
				if(!response){
					throw new Error('No data fetched')
				}
				this.job = response.data
			})
			.catch(err => {
				this.showAlert(err.message, 'error')
			})
	},

	methods: {
		// This function is also used in components/CrudTable.vue
		downloadResults: function() {
			confirm('Your download will start! Please make sure to validate the results!')
			let id = this.job.id
			let path = `private/jobs/${id}/results`
			return this.$api.request(path)
				.then(response => {
					if(!response){
						throw new Error('No data fetched')
					}
					this.showAlert('Download starting...', 'success')
					window.open(response.data.downloadLink, '_blank')
				})
				.catch(err => {
					this.showAlert(err.message, 'error')
				})
		},

	}
}



</script>

<style lang="scss" scoped>
		p {
				text-transform: capitalize;
		}
</style>
