import axios from 'axios'

const api = axios.create({
	baseURL: process.env.VUE_APP_BACKENDHOST || 'http://localhost:7000/',
	timeout: 1000
})
  
api.interceptors.request.use(
	config =>
	{
		config.headers['Authorization'] = 'Bearer ' + localStorage.token
		return config
	}
)

export default api
