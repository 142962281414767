<template>
	<div class="next-steps">
		<div class="row">
			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<font-awesome-icon
						icon="rocket"
						class="mr-2"
					/>Calculate distance matrix for thousands of rows
				</h6>
				<p>The Distance Matrix App allows you to process thousands of routes. Just upload one or more files, wait for a bit ☕ and your results should be in!</p>
			</div>

			<div class="col-md" />

			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<font-awesome-icon
						icon="car"
						class="mr-2"
					/>Support for different modalities
				</h6>
				<p>Routes can be calculated for different modalities. Currently it supports truck and car for all regions.</p>
			</div>
		</div>

		<div class="row">
			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<font-awesome-icon
						icon="keyboard"
						class="mr-2"
					/>Template input
				</h6>
				<p>
					The input for the route calculations can be uploaded using a CSV file (delimiter: ;). You can download the template here:
					<b-button
						href="template.csv"
						class="mb-2 btn-success"
						variant="primary"
						size="sm"
					>
						<font-awesome-icon icon="download" />
						Template
					</b-button>
				</p>
				<p>Don't add any custom columns to your file otherwise processing will fail.</p>
			</div>
			<div class="col-md" />
			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<font-awesome-icon
						icon="cogs"
						class="mr-2"
					/>How does it work?
				</h6>
				<p>Get the template input file, enter all your routes that you want to process. Log in and go to new job, then select the region and the modality (profile), and press create!</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HomeContent'
}
</script>
