<template>
	<div
		id="app"
		class="d-flex flex-column h-100"
	>
		<nav-bar />
		<div class="container flex-grow-1">
			<div class="mt-5">
				<router-view />
			</div>
		</div>
		<footer class="bg-light text-center p-3">
			<div class="logo-developer" />
			<p>
				Developed by Districon Solutions
			</p>
		</footer>
	</div>
</template>

<script>
import NavBar from './components/NavBar'

export default {
	components: {
		NavBar
	}
}
</script>

<style>
	@import './assets/style.css';
</style>