<template>
	<div>
		<h1>Overview of existing jobs</h1>
		<crud-table
			endpoint="/private/jobs"
			url-base="jobs"
			:columns="['id', 'createdAt', 'region', 'profile', 'state', 'actions']"
		/>
	</div>
</template>

<script>
import CrudTable from './CrudTable'

export default {
	components: {CrudTable},
}
</script>

