<template>
	<div>
		<hero />
		<homeContent />
	</div>
</template>

<script>
import Hero from '../components/Hero'
import HomeContent from '../components/HomeContent'

export default {
	name: 'Home',
	components: {
		Hero,
		HomeContent
	},
	created() {
		// Simple call to the public backend to ensure the back-end is alive warms-up
		return this.$api.request('public')
			.catch(err => {
				console.log('Backend is starting.')
			})
	}
}
</script>

<style lang="scss" scoped>
.next-steps {
	.fa-link {
		margin-right: 5px;
	}
}
</style>
