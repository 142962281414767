<template>
	<div>
		<Alert
			message="This page was not found"
			variant="danger"
		/>
	</div>
</template>

<script>
import Alert from '../components/Alert'

export default {
	name: 'PageNotFound',
	components: {
		Alert
	}
}
</script>

<style lang="scss" scoped>
.next-steps {
	.fa-link {
		margin-right: 5px;
	}
}
</style>
