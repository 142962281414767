<template>
	<div class="text-center hero">
		<h1 class="mb-8">
			Distance Matrix Calculator
		</h1>
		<p class="lead">
			Your favourite distance 📏 matrix calculator, <br> powered by
			<a 
				target="_blank" 
				href="https://www.districon.com"
			>Districon</a>
		</p>
	</div>
</template>

<script>
export default {
	name: 'Hero'
}
</script>
