<template>
	<div>
		<b-container class="text-right">
			<router-link
				to="/jobs/create"
				class="nav-link"
			>
				<b-button
					class="mb-2 btn-success"
					variant="primary"
					size="sm"
				>
					Create new +
				</b-button>
			</router-link>
		</b-container>
		<b-table
			striped
			hover
			:items="tableData"
			:fields="columns"
		>
			<template
				#cell(actions)="data"
				class="text-right"
			>
				<b-button-group>
					<router-link :to="urlBase + '/' + data.item.id">
						<b-button
							variant="info"
							size="sm"
						>
							Details
						</b-button>
					</router-link>
					<b-button
						v-b-modal="'edit-modal'"
						variant="danger"
						size="sm"
						@click="deleteItem(data.item)"
					>
						Delete
					</b-button>
					<b-button
						v-if="data.item.state==='finished'"
						variant="success"
						size="sm"
						@click="downloadResults(data.item)"
					>
						Download
					</b-button>
				</b-button-group>
			</template>
		</b-table>
	</div>
</template>

<script>

export default {
	props:['endpoint','urlBase', 'columns','formFields'],
	data() {
		return {
			editedItem: this.formFields,
			modalShow:false,
			itemEditable: false,
			editedIndex: -1,
			tableData : [],
			alertMessage: '',
			alertType: 'success',
			dismissSecs: 5000,
			dismissCountDown: 0
		}
	},

	created() {
		return this.$api.request(this.endpoint)
			.then(response=>{
				if(!response){
					throw new Error('No data fetched')
				}
				this.tableData = response.data
			})
			.catch(err => {
				this.showAlert(`Failed to fetch data: ${err.message}`, 'error')
			})                
	},

	methods: {    
		// This function is also used in views/Job
		downloadResults: function(item) {
			confirm('Your download will start! Please make sure to validate the results!')
			let id = item.id
			let path = `private/jobs/${id}/results`
			return this.$api.request(path)
				.then(response => {
					if(!response){
						throw new Error('No data fetched')
					}
					this.showAlert('Download starting...', 'success')
					window.open(response.data.downloadLink, '_blank')
				})
				.catch(err => {
					this.showAlert(err.message, 'error')
				})
		},


		deleteItem(item) {
			const index = this.tableData.indexOf(item)
			confirm('Are you sure you want to delete this item?')

			this.$api.delete(`${this.endpoint}/${item.id}`)
				.then(() => {
					this.tableData.splice(index, 1)
					this.showAlert('Removed job!')
				})
				.catch(err => {
					let message 
					if (err && err.response && err.response.data && err.response.data.message){
						message = err.response.data.message
					} else {
						message = err.message 
					}
					this.showAlert(`Failed to delete item: ${message}`, 'error')
				})     

		},
																 
	}
}
</script>

<style scoped>
 .table {
		 text-transform: capitalize
 }
</style>