<template>
	<div>
		<div class="row">
			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<b>
						My job finished with an error, what should I do?
					</b>
				</h6>
				<p>Please make sure you are following the exact definition of the input template. Check the file by using NotePad++ or similar software, not with Excel. Also, test with a small file first.</p>
			</div>

			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<b>
						How many routes can I upload in one file?
					</b>
				</h6>
				<p>It's recommended to reduce your file to approximately 1.000.000 (1 million) routes. We processes multiple jobs parallel, hence its wise to split it over multiple files.</p>
			</div>
		</div>

		<div class="row">
			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<b>
						Which data is used?
					</b>
				</h6>
				<p>We have preprocessed our data based on OpenStreetMap. Hence you will always need to validate the results.</p>
			</div>
			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<b>
						The results are too high/too low?
					</b>
				</h6>
				<p>Please contact <a href="mailto:support@ditricon.com">support@districon.com</a>. All data is preprocessed so we might need to tweak it a little.</p>
			</div>
		</div>


		<div class="row">
			<div class="col-md-5 mb-4">
				<h6 class="mb-3">
					<b>
						What about data retention?
					</b>
				</h6>
				<p>Your uploaded data and the corresponding results are stored for 7 days. Afterwards you won't be able to download the results anymore.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Faq'
}
</script>
