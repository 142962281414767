<template>
	<div class="nav-container mb-3">
		<nav class="navbar navbar-expand-md navbar-light bg-light">
			<div class="container">
				<div class="navbar-brand logo" />
				<button
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarNav"
					aria-controls="navbarNav"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-icon" />
				</button>

				<div
					id="navbarNav"
					class="collapse navbar-collapse"
				>
					<ul class="navbar-nav mr-auto">
						<li class="nav-item">
							<router-link
								to="/"
								class="nav-link"
							>
								Home
							</router-link>
						</li>
						<li
							v-if="$auth.isAuthenticated && !$auth.loading"
							class="nav-item"
						>
							<router-link
								to="/jobs"
								class="nav-link"
							>
								Jobs
							</router-link>
						</li>

						<li
							v-if="$auth.isAuthenticated && !$auth.loading"
							class="nav-item"
						>
							<router-link
								to="/jobs/create"
								class="nav-link"
							>
								New Job
							</router-link>
						</li>          
						<li
							v-if="$auth.isAuthenticated && !$auth.loading"
							class="nav-item"
						>
							<router-link
								to="/faq"
								class="nav-link"
							>
								FAQ
							</router-link>
						</li>                                 
					</ul>

					<ul class="navbar-nav d-none d-md-block">
						<li
							v-if="!$auth.isAuthenticated && !$auth.loading"
							class="nav-item"
						>
							<button
								id="qsLoginBtn"
								class="btn btn-primary btn-margin"
								@click.prevent="login"
							>
								Login
							</button>
						</li>

						<b-dropdown
							v-if="$auth.isAuthenticated && !$auth.loading"
							variant="link"
							toggle-class="text-decoration-none"
							no-caret
						>
							<template #button-content>
								<img
									:src="$auth.user.picture"
									alt="User's profile picture"
									class="nav-user-profile rounded-circle"
									width="50"
								>
							</template>
							<b-dropdown-item
								id="qsLogoutBtn"
								href="#"
								class="dropdown-item"
								@click.prevent="logout"
							>
								Log out
							</b-dropdown-item>
						</b-dropdown>
					</ul>

					<ul
						v-if="!$auth.isAuthenticated && !$auth.loading"
						class="navbar-nav d-md-none"
					>
						<button
							id="qsLoginBtn"
							class="btn btn-primary btn-block"
							@click="login"
						>
							Log in
						</button>
					</ul>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
export default {
	name: 'NavBar',
	methods: {
		login() {
			this.$auth.loginWithRedirect()
		},
		logout() {
			this.$auth.logout()
			localStorage.token = null
			this.$router.push({ path: '/' })
		}
	}
}
</script>

<style>
	#mobileAuthNavBar {
		min-height: 125px;
		justify-content: space-between;
	}

	#qsLoginBtn {
		margin: 15px;
	}
</style>
