import Vue from 'vue'
import Router from 'vue-router'

// Generic
import { authGuard } from '../auth'

// Views
import Home from '../views/Home.vue'
import Jobs from '../views/Jobs.vue'
import Job from '../views/Job.vue'
import Faq from '../views/Faq.vue'
import JobCreate from '../views/JobCreate.vue'
import PageNotFound from '../views/PageNotFound.vue'


Vue.use(Router)


const router = new Router({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home
		},
		{
			path: '/jobs',
			name: 'Jobs',
			component: Jobs,
			beforeEnter: authGuard      
		},
		{
			path: '/jobs/create',
			name: 'JobCrate',
			component: JobCreate,
			beforeEnter: authGuard      
		},  
		{
			path: '/jobs/:jobid',
			name: 'Job',
			component: Job,
			beforeEnter: authGuard      
		},
		{
			path: '/faq',
			name: 'Faq',
			component: Faq,
			beforeEnter: authGuard      
		},                 
		{
			path: '*',
			name: 'PageNotFound',
			component: PageNotFound,
			beforeEnter: authGuard      
		}
	]
})

export default router
