<template>
	<div>
		<jobsOverview />
	</div>
</template>

<script>
import JobsOverview from '../components/JobsOverview'

export default {
	name: 'Apps',
	components: {
		JobsOverview
	}
}
</script>

<style lang="scss" scoped>
</style>
