import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import { library, dom, config } from '@fortawesome/fontawesome-svg-core'
import { faArrowCircleLeft, fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'

// Render icons as SVG
config.autoReplaceSvg = 'nest'
dom.watch()

// Local imports
import App from './App.vue'
import router from './router'
import { Auth0Plugin } from './auth'
import api from './common/api'
import { domain, clientId, audience } from '../auth_config.json'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-toast-notification/dist/theme-default.css'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueToast)

library.add(faArrowCircleLeft, fas)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.prototype.$api = api

Vue.use(Auth0Plugin, {
	audience,
	domain,
	clientId,
	onRedirectCallback: appState => {
		router.push(
			appState && appState.targetUrl
				? appState.targetUrl
				: window.location.pathname
		)
	}
})

Vue.mixin({
	methods: {
		showAlert(message, alertType) {
			this.alertType = alertType || 'success'
			this.$toast.open({
				message: message,
				position: 'bottom-right',
				type: alertType,
				duration: 10000,
				dismissible: true
			})
		}
	}
})


new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
