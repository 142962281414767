<template>
	<div>
		<!-- Implement form like this one: https://jsfiddle.net/CristiJ/bt5dhqtf/286/ -->
		<b-container 
			v-if="!isUploading"
			class="text-right"
		>
			<b-button
				href="template.csv"
				class="mb-2 btn-success"
				variant="primary"
				size="sm"
			>
				<font-awesome-icon icon="download" />
				Download template
			</b-button>
		</b-container>

		<!-- The entire upload form; it will be hidden during upload -->
		<b-container
			v-if="!isUploading"
			class="bv-example-row"
		>
			<b-row align-h="center">
				<b-col cols="6">
					<b-alert
						variant="warning"
						show
					>
						Make sure that you use the inputfile template!
					</b-alert>
				</b-col>
			</b-row>		
			<b-row align-h="center">
				<b-col cols="6">
					<form-wizard
						title="Create new job"
						subtitle=""
						color="#19253f"
						error-color="#a94442"
						finish-button-text="Create job!"
						@on-complete="onComplete"
					>
						<tab-content
							title="Region"
							icon="fas fa-globe-africa"
							:before-change="validateFirstTab"
						>
							<vue-form-generator
								ref="firstTabForm" 
								:model="model"
								:schema="firstTabSchema"
								:options="formOptions"
							/>
						</tab-content>

						<tab-content
							title="Profile"
							icon="fas fa-truck-pickup"
							:before-change="validateSecondTab"
						>
							<vue-form-generator
								ref="secondTabForm" 
								:model="model"
								:schema="secondTabSchema"
								:options="formOptions"
							/>
						</tab-content>

						<tab-content
							title="File"
							icon="fas fa-file"
							:before-change="validateThirdTab"
						>
							<vue-form-generator
								ref="thirdTabForm" 
								:model="model"
								:schema="thirdTabSchema"
								:options="formOptions"
							/>
						</tab-content>                        
											
						<tab-content
							title="Validate"
							icon="fas fa-check"
						>
							<b-row align-h="center">
								<h4>Details: </h4>
							</b-row>
							<b-row align-h="center">
								<div class="panel-body">
									<p><b>Region: </b>{{ model.region }}</p>
									<p><b>Profile: </b>{{ model.profile }}</p>
									<p><b>File: </b>{{ model.inputFile.name }}</p>
								</div>
							</b-row>
						</tab-content>
					</form-wizard>
				</b-col>
			</b-row>
		</b-container>

		<b-container
			v-if="isUploading"
			class="bv-example-row"
		>
			<b-row align-h="center">
				<b-col
					cols="6"
					class="text-center"
				>
					<h3>Uploading file: {{ uploadProgress }}%</h3>
					<h5 v-if="uploadProgress === 100">
						We are storing your file, this could take a few seconds
					</h5>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import VueFormGenerator from 'vue-form-generator'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import _ from 'lodash'

export default {
	name: 'JobCreate',
	components: {
		'vue-form-generator': VueFormGenerator.component,
		FormWizard,
		TabContent
	},
	data () {
		return {
			isUploading: false,
			uploadProgress: 0,
			availableOSRMData: {},
			model:{
				region:'',
				profile: '',
				inputFile: '.',
			},
			formOptions: {
				validationErrorClass: 'has-error',
				validationSuccessClass: 'has-success',
				validateAfterChanged: true
			},
			firstTabSchema:{
				fields:[{
					type: 'select',
					inputType: 'text',
					label: 'Region:',
					model: 'region',
					required:true,
					values: [],
					validator:VueFormGenerator.validators.string,
					styleClasses:'col-md-7 mx-auto'
				}
				]
			},
			secondTabSchema:{
				fields:[
					{
						type: 'select',
						inputType: 'text',
						label: 'Profile: ',
						model: 'profile',
						required:true,
						values:['Car', 'Truck'],
						validator:VueFormGenerator.validators.string,
						styleClasses:'col-md-7 mx-auto'
					},
				]
			},
			thirdTabSchema:{
				fields:[
					{
						type: 'upload',
						inputName: 'inputFile',
						label: 'Select your CSV file:',
						model: 'inputFile',
						required:true,
						validator:VueFormGenerator.validators.string,
						styleClasses:'col-md-7 mx-auto',
						onChanged(model, schema, event) {
							this.model.inputFile = event.target.files[0]
						}

					},
				]
			}
		}
	},

	created() {
		// Get Regions/profile from the server
		return this.$api.request('/private/regions')
			.then(response=>{
				if(!response){
					throw new Error('No data fetched')
				}
				this.availableOSRMData = response.data

				// Update the first input field
				this.firstTabSchema.fields[0].values = this.getRegions()
			})
			.catch(err => {
				this.showAlert(err.message, 'error')
			})                
	},
	methods: {

		onComplete: function(){
			let context = this //Needed because we will be nesting functions yeey
			this.isUploading = true
			let data = new FormData()
			data.append('region', this.model.region)
			data.append('profile', this.model.profile)
			data.append('inputfile', this.model.inputFile)

			let config = {
				header: {
					'Content-Type': 'multipart/form-data',
				},
				timeout: 0,
				onUploadProgress: function(progressEvent) {
			  		context.uploadProgress = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
				}
			}

			this.$api.post('/private/jobs', data, config)
				.then(() =>{
					this.showAlert('Job added succesfully')
					this.$router.push({ name: 'Jobs' })
				})
				.catch(err => {
					this.showAlert(`Failed to created job.  ${err.message}`, 'error')
					this.isUploading = false
					this.uploadProgress = 0
				})
			   
		},
		validateFirstTab: function(){
			if (!this.$refs.firstTabForm.validate()){
				return this.$refs.firstTabForm.validate()
			} else{
				// Retrieve profiles for the second tab
				this.secondTabSchema.fields[0].values = this.getProfilesForRegion(this.model.region)
				return this.$refs.firstTabForm.validate()
			}
		},
		validateSecondTab: function(){
			return this.$refs.secondTabForm.validate()
		},
		validateThirdTab: function(){
			return this.$refs.secondTabForm.validate()
		},
		getRegions: function(){ 
			let input = this.availableOSRMData
			let result = []
			for (const regionwithprofiles of input){
				let regionName = regionwithprofiles.name.toLowerCase()
				let regionNameCapitalized = regionName.charAt(0).toUpperCase() + regionName.slice(1)
				result.push(regionNameCapitalized)
			}
			return result
		},
		getProfilesForRegion: function(requestedRegion){
			let region =_.find(this.availableOSRMData, function(regionwithprofiles) { return regionwithprofiles.name === requestedRegion.toLowerCase() })
			let profiles = region.profiles.map(profile => profile.charAt(0).toUpperCase() + profile.slice(1))
			return profiles
		}            
			
	}        

}



</script>

<style scoped>
select, select option {text-transform:capitalize}

pre {
	overflow: auto;
}
	pre .string { color: #885800; }
	pre .number { color: blue; }
	pre .boolean { color: magenta; }
	pre .null { color: red; }
	pre .key { color: green; }  

</style>
